/* 
 * @Author: 曹俊杰 
 * @Date: 2022-05-16 16:25:33
 * @Module: 公司简介
 */
 <template>
  <div class="box">
    <el-card shadow="never" body-style="{padding:'0px'}">
      <div class="pdf" v-loading="loading">
        <pdf :src="pdfSrc" v-for="i in numPages" :key="i" :page="i"></pdf>
      </div>
    </el-card>
    <el-button size="medium" v-if="downFile" @click="downFileFn" icon="el-icon-download" class="down" plain>下载</el-button>
  </div>
</template>
 <script>
import pdf from "vue-pdf";
export default {
  components: {
    pdf
  },
  data () {
    return {
      pdfSrc: '',
      numPages: 1, //  pdf 文件总页数
      loading: true,
      downFile: false
    };
  },
  watch: {
    "$route.query": {
      handler ({ fileUrl, downFile }) {
        this.getNumPages(fileUrl)
        this.downFile = downFile ? true : false
        // this.getNumPages("https://meimengapp.oss-cn-beijing.aliyuncs.com/zhq/test/files/3031481_20220512095423_62430CA2A18.pdf")
      },
      immediate: true
    }
  },
  mounted () {
  },
  methods: {
    async getNumPages (url) {
      const loadingTask = pdf.createLoadingTask(decodeURIComponent(url))
      try {
        const pdf = await loadingTask.promise
        this.pdfSrc = loadingTask
        this.numPages = pdf.numPages;
        this.loading = false;
      } catch (err) {
        console.error('pdf加载失败:', err);
        this.loading = false;
      }
    },
    downFileFn () {
      window.open(this.$route.query.fileUrl)
    }
  },
};
 </script>
 <style lang='scss' scoped>
/deep/.el-card__body {
  padding: 0px;
}
.box {
  width: 70%;
  margin: 0 auto;
}
.pdf {
  margin-top: 0;
  // border: 1px solid #e3e3e3;
}
.down {
  position: fixed;
  right: 100px;
  bottom: 80px;
}
</style>

